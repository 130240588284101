import classes from "./TheHome.module.scss";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import avatar from '../../assets/images/avatar.png'
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchRemote } from "../../functions";

interface HomepageData {
    firstName: string;
    lastName: string;
    position: string;
    cardTitle: string;
    cardSubtitle: string;
    cardCopy: string;
}

const TheHome: React.FC = (props) => {
    const [data, setData] = useState<HomepageData>();
    useEffect(() => {
        fetchRemote<HomepageData>(process.env.REACT_APP_REST_HOST + "/homepage.json").then(e => {
            setData(e);
        })
    }, [])
    return (
        <div className={classes.main}>
            <div className={classes["background"]}>
                <div className={classes["left"]}></div>
                <div className={classes["right"]}></div>
            </div>
            <div className={[classes["card"]].join(' ')}>
                <div className={[classes["left"], "app-box-shadow"].join(' ')}>
                    <div className={classes["left-inner"]}>
                        <img src={avatar} />
                        <h1>
                            {data?.firstName}
                            <br />
                            {data?.lastName}
                        </h1>
                        <div className={classes["separator"]}></div>
                        <p>{data?.position}</p>
                        <div className={classes.buttons}>
                            <Link to={'/resume'} className="btn-style-1">RESUME</Link>
                            <Link to='/projects' className="btn-style-2">PROJECTS</Link>
                        </div>
                    </div>
                    <SocialLinks />
                </div>
                <div className={classes["right"]}>
                    <h2>{data?.cardTitle}</h2>
                    <p>{data?.cardSubtitle}</p>
                    <div className={classes.buttons}>
                        <Link to={'/resume'} className="btn-style-1">RESUME</Link>
                        <Link to='/projects' className="btn-style-2">PROJECTS</Link>
                    </div>
                    <p>{data?.cardCopy}</p>
                </div>
            </div>
        </div>
    );
};

export default TheHome;
