import classes from './ResumePage.module.scss';
import { fetchRemote } from "../../functions";
import { useState, useEffect } from 'react';

interface WorkExperienceMeta {
    time: string;
    title: string;
    company: string;
    copy: string;
}

interface educationMeta {
    time: string;
    title: string;
    company: string;
    copy: string;
}


const ResumePage: React.FC = (props) => {
    const [workExperienceArr, setWorkExperienceArr] = useState<WorkExperienceMeta[]>();
    const [educationArr, setEducationArr] = useState<educationMeta[]>();

    useEffect(() => {
        fetchRemote<WorkExperienceMeta[]>(process.env.REACT_APP_REST_HOST + "/workExperience.json").then(e => {
            setWorkExperienceArr(e)
        })
    }, [])

    useEffect(() => {
        fetchRemote<educationMeta[]>(process.env.REACT_APP_REST_HOST + "/education.json").then(e => {
            setEducationArr(e)
        })
    }, [])



    return (
        <div className={classes.main}>
            <div className={classes.body}>
                <h1 className='app-list-item'>Resume</h1>
                <h2>Experience</h2>
                {workExperienceArr?.map(entry => <ExperienceCard data={entry} key={entry.time} />)}
                <h2>Education</h2>
                {educationArr?.map(entry => <EducationCard data={entry} key={entry.time} />)}
            </div>
        </div>
    )
}

const ExperienceCard: React.FC<{
    data: {
        time: string,
        title: string,
        company: string,
        copy: string,
    }
}> = (props) => {
    const time = props.data.time
    const title = props.data.title
    const company = props.data.company
    const copy = props.data.copy
    return (
        <div className={[classes['experience-card'], 'app-box-shadow'].join(' ')}>
            <div className={classes.left}>
                <h4>{time}</h4>
                <h3>{title}</h3>
                <p>{company}</p>
            </div>
            <div className={classes.right}>
                {copy.split(";").map(row => row ? <p className='app-list-item-outside' key={row}>{row}</p> : null)}
            </div>
        </div>
    )
}


const EducationCard: React.FC<{
    data: {
        time: string,
        title: string,
        company: string,
        copy: string,
    }
}> = (props) => {
    const time = props.data.time
    const title = props.data.title
    const company = props.data.company
    const copy = props.data.copy
    return (
        <div className={[classes['education-card'], 'app-box-shadow'].join(' ')}>
            <div className={classes.left}>
                <h4>{time}</h4>
                <h3>{title}</h3>
                <p>{company}</p>
            </div>
            <div className={classes.right}>
                {copy.split(";").map(row => row ? <p className='app-list-item' key={row}>{row}</p> : null)}
            </div>
        </div>
    )
}

export default ResumePage;