import classes from './ProjectPage.module.scss'
import { fetchRemote } from "../../functions";
import { useState, useEffect } from 'react';

interface ProjectMeta {
    name: string;
    url: string;
    copy: string;
    img: string
}

const ProjectPage: React.FC = (props) => {

    const [projectArr, setProjectArr] = useState<ProjectMeta[]>();
    useEffect(() => {
        fetchRemote<ProjectMeta[]>(process.env.REACT_APP_REST_HOST + "/projects.json").then(e => {
            setProjectArr(e)
        })
    }, [])


    return (
        <div className={classes.main}>
            <div className={classes.body}>
                <h1 className='app-list-item'>Projects</h1>
                <h2></h2>
                {projectArr?.map(e => <ProjectCard data={e} key={e.url} />)}
            </div>
        </div>
    )
}


const ProjectCard: React.FC<{
    data: {
        name: string,
        url: string,
        copy: string,
        img: string,
    }
}> = (props) => {
    const name = props.data.name
    const url = props.data.url
    const copy = props.data.copy
    const img = props.data.img
    return (
        <div className={[classes['project-card'], 'app-box-shadow'].join(' ')}>
            <div className={classes.left}>
                <h3>{name}</h3>
                {copy.split(";").map(row => row ? <p key={row}>{row}</p> : null)}
            </div>
            <a className={classes.right} href={url} target="_blank" rel="noopener">
                <img src={img} />
            </a>
        </div>
    )
}


export default ProjectPage;