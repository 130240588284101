import classes from './SocialLinks.module.scss'
import linkedinIcon from '../../assets/images/Linkedin.png'
import githubIcon from '../../assets/images/Github.png'

const SocialLinks: React.FC = (props) => {

    const data = [
        {
            name: "Linkedin",
            icon: linkedinIcon,
            url: "https://www.linkedin.com/in/tonystevenj"
        },
        {
            name: "Github",
            icon: githubIcon,
            url: "https://github.com/tonystevenj"
        },
    ]

    return (
        <div className={classes.main}>
            <div>
                {data.map(entry => {
                    return <a href={entry.url} target="_blank" rel="noreferrer" key={entry.url}><img src={entry.icon} alt={entry.name} /></a>
                })}
            </div>
        </div>
    )
}

export default SocialLinks;