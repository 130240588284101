import SocialLinks from '../SocialLinks/SocialLinks'
import classes from './TheFooter.module.scss'


const TheFooter: React.FC = (props) => {
    const email = "tonystevenj@gmail.com"
    return (
        <div className={classes.main}>
            <div className={classes.left}>
                <p>@2023 by Hongjing Wang.</p>
            </div>
            <div className={classes.right}>
                <ContactEntry title={"Write"}>
                    <a href={`mailto:${email}`}>{email}</a>
                </ContactEntry>
                <ContactEntry title={"Follow"}>
                    <SocialLinks/>
                </ContactEntry>
            </div>
        </div>
    )
}

const ContactEntry: React.FC<{
    title: string
}> = (props) => {
    const title = props.title
    return (
        <div className={classes.entry}>
            <h3>{title}</h3>
            {props.children}
        </div>
    )
}

export default TheFooter;