import "./App.scss";
import TheHome from "./pages/TheHome/TheHome";
import ResumePage from "./pages/ResumePage/ResumePage";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import { Routes, Route } from "react-router-dom";
import TheHeader from "./components/TheHeader/TheHeader";
import TheFooter from "./components/TheFooter/TheFooter";
import useScrollReset from "./hooks/useScrollReset";
function App() {
    useScrollReset()
    return (
        <div className="App">

            <TheHeader />
            <Routes>
                <Route path="/" element={<TheHome />} />
                <Route path="/resume" element={<ResumePage />} />
                <Route path="/projects" element={<ProjectPage />} />
            </Routes>
            <TheFooter/>
        </div>
    );
}

export default App;
