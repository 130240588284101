import classes from './TheHeader.module.scss'
import React, { useEffect, useRef, useState, useContext, Fragment } from 'react';
// import GlobalStore from '../../contexts/GlobalStore'
import { Link, useParams } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';



function TheHeader(props) {

    const windowSize = useWindowSize()
    // const store = useContext(GlobalStore)
    const wideScreen = windowSize.width >= 480
    const [showMobileNavBody, setShowMobileNavBody] = useState(false)

    return (
        <Fragment>
            <div className={classes.spacer}></div>
            {
                wideScreen ?
                    <div className={[classes.main].join(' ')} >
                        <h2 className={[classes.logo,"app-list-item"].join(" ")}>
                            <Link to={`/`} className={classes['btn-logo']}>Hongjing Wang</Link>
                            <span>/ Software Engineer</span>
                        </h2>
                        <nav className={classes.nav}>
                            <ul>
                                <li><Link to={`/resume`}>Resume</Link></li>
                                <li><Link to={`/projects`}>Projects</Link></li>
                            </ul>
                        </nav>
                    </div>
                    :
                    <div className={[classes['mobile-main'], showMobileNavBody ? classes['mobile-active'] : null].join(' ')} >
                        <div className={classes['mobile-main-title']}>
                            <div className={classes.logo}><Link to={`/`}>Hongjing Wang</Link></div>
                            <div className={[classes['mobile-toggle'],].join(' ')}
                                onClick={() => {
                                    setShowMobileNavBody(!showMobileNavBody)
                                }}>
                                <div className={classes['mobile-toggle-bar']}></div>
                            </div>
                        </div>
                        <div className={classes['mobile-main-body']}>
                            <nav>
                                <ul>
                                    <li onClick={() => { setShowMobileNavBody(!showMobileNavBody) }}><Link to={`/`}>Home</Link></li>
                                    <li onClick={() => { setShowMobileNavBody(!showMobileNavBody) }}><Link to={`/resume`}>Resume</Link></li>
                                    <li onClick={() => { setShowMobileNavBody(!showMobileNavBody) }}><Link to={`/projects`}>Projects</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
            }

        </Fragment>
    )
}

export default TheHeader;